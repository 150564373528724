import React, { FC } from "react";
import {
  MarkerIcon,
  RightArrowIcon,
  UserAccountIcon,
  BusIcon,
  CutleryIcon,
  MoneyIcon,
  InfoIcon,
} from "@travelquest/web-components";
import {
  StyledCard,
  Field,
  Title,
  Value,
  CardLine,
  MultipleField,
  StyledIcon,
  TotalBlock,
  TotalText,
  TotalPrice,
  StyledCardMobile,
  HotelInfo,
  HotelImage,
  HotelName,
  StyledStars,
  HotelLocation,
  TimezoneWarning,
} from "./BookingInformation.styled";
import { getImageFromServer, dayjsWithTimezone } from "../../helpers";
import { ContextType, useAppState } from "../../store";
import { calculateAddons } from "./calculateAddons";
import { calculateExcursions } from "./calculateExcursions";
import { calculateTotalPrice } from "./calculateTotalPrice";
import { calculateRooms } from "./calculateRooms";
import { mealTypes, transportOptions } from "./constants";
import { useTranslation } from "react-i18next";
import { StyledInfoIcon } from "../Checkbox/Checkbox.styled";
import { Tooltip } from "react-tooltip";

export type Props = {
  booking: BookingType;
  loading: boolean;
};

export const BookingInformation: FC<Props> = ({ booking, loading }) => {
  const {
    appState: { brand },
  } = useAppState() as ContextType;
  const offset = new Date().getTimezoneOffset();
  const showTimeZoneIndicator = offset !== -120;
  const { targetAudienceEnabled } = brand.features;
  const volunteerFee = booking?.pricingInformation?.volunteerFee || 0;
  const { t } = useTranslation(["general", "addons"]);

  const travelInsurancePriceNew =
    booking.brandAccommodation?.brandDestination?.insurances?.find(
      (insurance) => insurance.type === "travel"
    )?.price || 0;
  const annulationInsurancePriceNew =
    booking.brandAccommodation?.brandDestination?.insurances?.find(
      (insurance) => insurance.type === "annulation"
    )?.price || 0;
  const bothInsurancePriceNew =
    booking.brandAccommodation?.brandDestination?.insurances?.find(
      (insurance) => insurance.type === "annulationTravel"
    )?.price || 0;

  const travellers = booking?.travellers || [];

  const numberofTravelInsurances = travellers.filter(
    (traveller) => traveller.hasTravelInsurance
  )?.length;
  const numberofAnnulationInsurances = travellers.filter(
    (traveller) => traveller.hasAnnulationInsurance
  )?.length;
  const numberofBothInsurances = travellers.filter(
    (traveller) => traveller.hasBothInsurances
  )?.length;

  if (loading) {
    return null;
  }

  let totalTravellers = 0;
  booking?.rooms?.forEach((r: RoomType) => {
    totalTravellers = totalTravellers + (r.size + 1);
  });

  const accommodation = booking?.brandAccommodation?.accommodation;

  let startDate: string = "";
  const _startDate = dayjsWithTimezone(booking.period.start * 1000);

  if (booking.transport === "bus") {
    startDate = _startDate.subtract(1, "day").format("DD/MM/YYYY");
  } else {
    startDate = _startDate.format("DD/MM/YYYY");
  }

  let endDate: string = "";
  const _endDate = dayjsWithTimezone(booking.period.end * 1000);

  if (booking.transport === "bus") {
    endDate = _endDate.add(1, "day").format("DD/MM/YYYY");
  } else {
    endDate = _endDate.format("DD/MM/YYYY");
  }

  const prices =
    booking?.period?.prices?.[booking?.mealType]?.[booking?.transport];

  const rooms = calculateRooms(booking?.rooms);

  const total = calculateTotalPrice(
    booking.rooms,
    prices,
    travelInsurancePriceNew * numberofTravelInsurances,
    annulationInsurancePriceNew * numberofAnnulationInsurances,
    bothInsurancePriceNew * numberofBothInsurances,
    volunteerFee
  );

  const excursionTotals = calculateExcursions(booking?.travellers);
  const addonTotals = calculateAddons(
    booking?.travellers,
    booking?.brandAccommodation?.brandDestination?.addons,
    booking?.period?.start
  );

  return (
    <div>
      <HotelInfo>
        <HotelImage
          src={getImageFromServer(accommodation.coverImage?.url, "1:1", 400)}
          alt={accommodation.coverImage?.alt}
        />
        <HotelName>
          {accommodation.name?.nlbe}
          <span>
            <StyledStars count={accommodation?.rating} />
          </span>
          <HotelLocation color={brand.color}>
            <MarkerIcon /> {accommodation.address}
          </HotelLocation>
          <MultipleField $isMobile={true}>
            <Value>{startDate}</Value>
            <StyledIcon>
              <RightArrowIcon />
            </StyledIcon>
            <Value>{endDate}</Value>
          </MultipleField>
          {!showTimeZoneIndicator && (
            <TimezoneWarning color={brand.color}>
              <InfoIcon />
              Opgelet, alle tijden zijn in tijdszone GMT+2
            </TimezoneWarning>
          )}
        </HotelName>
      </HotelInfo>
      <StyledCardMobile>
        <Field>
          <StyledIcon>
            <UserAccountIcon />
          </StyledIcon>
          <CardLine />
          <Value>{totalTravellers}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <BusIcon />
          </StyledIcon>
          <CardLine />
          <Value>{transportOptions[booking.transport]}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <CutleryIcon />
          </StyledIcon>
          <CardLine />
          <Value>{mealTypes[booking.mealType]}</Value>
        </Field>
        <Field>
          <StyledIcon>
            <MoneyIcon />
          </StyledIcon>
          <CardLine />
          <Value>€ {total + excursionTotals.price + addonTotals.price}</Value>
        </Field>
      </StyledCardMobile>
      <StyledCard>
        <Field>
          <Title>Aantal reizigers</Title>
          <Value>{totalTravellers} personen</Value>
        </Field>
        <CardLine />
        <MultipleField>
          <div>
            <Title>Van</Title>
            <Value>{startDate}</Value>
          </div>
          <StyledIcon>
            <RightArrowIcon />
          </StyledIcon>
          <div>
            <Title>Tot</Title>
            <Value>{endDate}</Value>
          </div>
        </MultipleField>
        <CardLine />
        <Field>
          <Title>{t("general:transport")}</Title>
          <Value>{transportOptions[booking.transport]}</Value>
        </Field>
        <CardLine />
        <Field>
          <Title>Maaltijdsformule</Title>
          <Value>{mealTypes[booking.mealType]}</Value>
        </Field>
        <CardLine />
        {targetAudienceEnabled && (
          <>
            <Field>
              <Title>Doelgroep</Title>
              <Value>{booking.targetAudience?.name?.nlbe}</Value>
            </Field>
            <CardLine />
          </>
        )}

        {Object.keys(rooms).map((key) => (
          <MultipleField
            key={`room-${key}`}
            style={{ justifyContent: "space-between" }}
          >
            <Title>
              {rooms[key]}x {Number(key)}-persoonskamer
            </Title>
            <Value>
              €{" "}
              {Math.ceil(rooms[key] * prices[Number(key) - 1].discounted) *
                Number(key)}
            </Value>
          </MultipleField>
        ))}
        {numberofTravelInsurances > 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {numberofTravelInsurances}x {t("addons:travelinsurance")}
            </Title>
            <Value>
              € {travelInsurancePriceNew * numberofTravelInsurances}
            </Value>
          </MultipleField>
        ) : null}
        {numberofAnnulationInsurances > 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {numberofAnnulationInsurances}x {t("addons:annulationInsurance")}
            </Title>
            <Value>
              € {annulationInsurancePriceNew * numberofAnnulationInsurances}
            </Value>
          </MultipleField>
        ) : null}
        {numberofBothInsurances > 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {numberofBothInsurances}x {t("addons:bothInsurance")}
            </Title>
            <Value>€ {bothInsurancePriceNew * numberofBothInsurances}</Value>
          </MultipleField>
        ) : null}
        {excursionTotals.total !== 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>
              {excursionTotals.total}x excursies, events en partydeals
            </Title>
            <Value>€ {excursionTotals.price}</Value>
          </MultipleField>
        ) : null}
        {addonTotals.total !== 0 ? (
          <MultipleField style={{ justifyContent: "space-between" }}>
            <Title>{addonTotals.total}x extra's.</Title>
            <Value>€ {addonTotals.price}</Value>
          </MultipleField>
        ) : null}
        {volunteerFee !== 0 ? (
          <>
            <MultipleField style={{ justifyContent: "space-between" }}>
              <Title
                id="volunteerid"
                data-tip="volunteer"
                data-tooltip-content={`Bij ${brand.name} zorgt onze crew voor een onvergetelijke reiservaring, van voorbereiding tot begeleiding ter plaatse. Ze volgen jaarlijks een uitgebreide opleiding of bijscholing, zodat ze onze jongeren de beste begeleiding kunnen bieden. Met jouw bijdrage kunnen we onze crew blijven opleiden en jou een topvakantie garanderen!`}
              >
                <Tooltip
                  className="tooltip"
                  anchorId={"volunteerid"}
                  positionStrategy="fixed"
                />
                {totalTravellers}x Vrijwilligersbijdrage{" "}
                <span data-tip data-for="tip">
                  <StyledInfoIcon />
                </span>
              </Title>
              <Value>€ {totalTravellers * volunteerFee}</Value>
            </MultipleField>
          </>
        ) : null}
        <TotalBlock color={brand.color}>
          <MultipleField
            style={{
              padding: 0,
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div>
              <TotalText>Totaalprijs</TotalText>
              <TotalText>Voor {totalTravellers} reizigers</TotalText>
            </div>
            <TotalPrice>
              € {total + excursionTotals.price + addonTotals.price}
            </TotalPrice>
          </MultipleField>
        </TotalBlock>
      </StyledCard>
    </div>
  );
};
